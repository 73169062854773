import React from 'react'
import { graphql } from 'gatsby'
import { ImageWithText, ImageCards } from '../components/slices'
import { Image } from 'react-bootstrap'
import { Layout, HeroImage, LogoHeroImage, SEO } from '../components'

export const query = graphql`
  query MyQuery {
    prismicHomePage {
      data {
        hero_image {
          url
          alt
        }
        logo_image {
          url
          alt
        }
        body {
          ... on PrismicHomePageBodyImageWithText {
            id
            primary {
              heading {
                text
              }
              cta_text {
                text
              }
              cta_link {
                url
                target
              }
              rich_text {
                raw
              }
              image {
                url
                alt
              }
              display_image_on_left_
            }
          }
          ... on PrismicHomePageBodyFullWidthImage {
            id
            primary {
              image {
                url
                alt
              }
            }
          }
          ... on PrismicHomePageBodyImageCards {
            id
            primary {
              image_one {
                alt
                url
              }
              link_one {
                url
                target
              }
              title_one {
                text
              }
              image_two {
                alt
                url
              }
              title_two {
                text
              }
              link_two {
                url
                target
              }
              image_three {
                url
                alt
              }
              title_three {
                text
              }
              link_three {
                url
                target
              }
            }
          }
        }
      }
    }
  }
`
const sliceResolver = (slice) => {
  const { id, __typename: typeName, primary } = slice

  switch (typeName) {
    case 'PrismicHomePageBodyImageWithText':
      return (
        <ImageWithText
          heading={primary.heading.text}
          richText={primary.rich_text.raw}
          image={{
            src: primary.image.url,
            alt: primary.image.alt,
          }}
          cta={{
            text: primary.cta_text.text,
            href: primary.cta_link.url,
            target: primary.cta_link.target,
          }}
          displayImageLeft={primary.display_image_on_left_}
        />
      )
    case 'PrismicHomePageBodyFullWidthImage':
      return (
        <Image
          fluid
          src={primary.image.url}
          alt={primary.image.alt}
          className="huis-full-span-image"
        />
      )
    case 'PrismicHomePageBodyImageCards':
      const cards = ['one', 'two', 'three'].map((num) => {
        return {
          imgSrc: primary[`image_${num}`].url,
          imgAlt: primary[`image_${num}`].alt,
          linkUrl: primary[`link_${num}`].url,
          linkTarget: primary[`link_${num}`].target,
          title: primary[`title_${num}`].text,
        }
      })
      return <ImageCards cards={cards} />
    default:
      return null
  }
}

export const Homepage = ({ data }) => {
  const homePage = data.prismicHomePage.data
  const heroImage = homePage.hero_image
  const logoHeroImage = homePage.logo_image
  return (
    <Layout
      footerProps={{ hasBorderTop: false }}
      homePageHasLogo={logoHeroImage.url && logoHeroImage.url.length > 0}
    >
      <SEO title="Home | Huis Shave Ice" />
      {heroImage && heroImage.url ? (
        <HeroImage url={heroImage.url} altText={heroImage.alt}></HeroImage>
      ) : (
        <LogoHeroImage
          url={logoHeroImage.url}
          altText={logoHeroImage.alt}
        ></LogoHeroImage>
      )}
      <h1 className="sr-only">Home</h1>

      {/* {body.length &&
        body.map((slice) => {
          return sliceResolver(slice)
        })} */}
    </Layout>
  )
}

export default Homepage
